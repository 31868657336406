///
/// @file  _genie-base-print.scss
///
/// \brief Pull all parts of the theme scss together into one file
///

.site-content {
  width: 90%;
  margin: 0 auto;
}
